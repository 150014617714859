import React from 'react';
import { Formik, Form as FormikForm, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';

import { MESSAGE_REQUIRED_FIELD } from '../../utils/constants';
import { showFormError } from '../../utils/form';
import FormFieldNew from '../atoms/form-field-new';
import SearchIcon from '../../images/icons/search.svg';

const validationSchema = Yup.object().shape({
  query: Yup.string().required(MESSAGE_REQUIRED_FIELD),
});

const SearchForm = React.forwardRef(({ formName, initialValues, onSubmit }, ref) => {
  return (
    <div className="search-form">
      <div className="container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ errors, touched }) => {
            return (
              <FormikForm name={formName} noValidate>
                <div className="search-input">
                  <Field
                    component={FormFieldNew}
                    className="search-input__input"
                    errored={showFormError('query', errors, touched)}
                    formName={formName}
                    id="query"
                    label="Query"
                    wrapperClass="form__field search-input"
                    name="query"
                    required
                    type="search"
                    innerRef={ref}
                    error={(name, cssClass) => <ErrorMessage className={cssClass} name={name} component="div" />}
                  >
                    <button className="search-input__button" type="submit">
                      <span className="search-input__button-text">Submit</span>
                      <SearchIcon className="search-input__button-icon" />
                    </button>
                  </Field>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </div>
  );
});

export default SearchForm;
