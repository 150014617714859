import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';
import { useQueryParamString } from 'react-use-query-param-string';

import { getOffset, getPageCount } from '../utils/search';
import { scrollToRef } from '../utils/scroll-to-ref';
import Seo from '../components/seo';
import Layout from '../components/layout';
import PageHeader from '../components/molecules/page-header';
import Archive from '../components/organisms/archive';
import BlogPostCard from '../components/atoms/blog-post-card';
import SearchForm from '../components/molecules/search-form';

const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_FRONT_END_API_KEY);
const index = client.initIndex(process.env.ALGOLIA_INDEX_NAME);

const postsPerPage = 8;

async function searchData(query, offset, length) {
  const data = await index.search(query, {
    attributesToRetrieve: ['title', 'slug', 'excerpt'],
    offset,
    length,
    removeStopWords: true,
    queryLanguages: ['en'],
  });

  return {
    results: data.hits,
    total: data.nbHits,
  };
}

const Search = ({ data }) => {
  const ogImage = data.page.yoast.ogImage && data.page.yoast.ogImage.localFile;
  const twitterImage = data.page.yoast.twitterImage && data.page.yoast.twitterImage.localFile;

  const searchWrapper = useRef(null);
  const searchInput = useRef(null);

  const [query, setQuery, queryStringInitialized] = useQueryParamString('query', '');

  const [initialValues, setInitialValues] = useState({
    query,
  });

  const [results, setSearchResults] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);

  const resultsTitle = isLoading ? 'Loading' : `${resultCount} results`;

  useEffect(() => {
    searchInput.current.focus();
  }, []);

  const hasNoResults = hasSearched && results.length === 0;

  const pagination = {
    filtered: false,
    current: currentPage,
    pageCount: getPageCount(resultCount, postsPerPage),
  };

  async function runSearch({
    query,
    page,
    postsPerPage = 8,
    scrollToOnComplete = true,
    onBeforeSearch = null,
    onComplete = null,
    onError = null,
    minimumTime = 500,
  }) {
    setHasErrored(false);
    setLoading(true);

    try {
      const now = new Date().valueOf();

      setHasSearched(true);

      if (onBeforeSearch !== null) {
        onBeforeSearch(query);
      }

      setQuery(query);
      setCurrentPage(page);

      const data = await searchData(query, getOffset(page, postsPerPage), postsPerPage);
      const wait = Math.max(0, -(new Date().valueOf() - now) + minimumTime);

      await new Promise(resolve => setTimeout(resolve, wait));

      setSearchResults(data.results);
      setResultCount(data.total);

      if (onComplete) {
        onComplete();
      }

      setLoading(false);

      if (scrollToOnComplete) {
        scrollToRef(searchWrapper);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setHasErrored(true);
      setSearchResults([]);
      setResultCount(0);
      setCurrentPage(null);

      if (onError !== null) {
        onError(e);
      }
    }
  }

  useEffect(() => {
    function initialSearch() {
      runSearch({
        query,
        page: 1,
        onBeforeSearch: query => {
          setInitialValues({
            query,
          });
        },
      });
    }

    if (typeof query !== 'undefined' && query !== null && query.length > 0) {
      initialSearch();
    }
  }, [queryStringInitialized]);

  const handlePageChange = page => runSearch({ query, page, postsPerPage });

  const onPageClick = index => handlePageChange(index);

  const onNextClick = () => handlePageChange(currentPage + 1);

  const onPreviousClick = async () => handlePageChange(currentPage - 1);

  return (
    <Layout>
      <Seo
        fallbackTitle={data.page.title}
        title={data.page.yoast.title}
        description={data.page.yoast.metadesc}
        ogImage={ogImage}
        twitterImage={twitterImage}
        noRobots
      />
      <PageHeader title={data.page.title} />

      <div className="search">
        <div className="search__form-wrapper" ref={searchWrapper}>
          <SearchForm
            formName="site-search"
            initialValues={initialValues}
            ref={searchInput}
            onSubmit={(values, actions) => {
              const { setSubmitting, setStatus } = actions;
              const { query } = values;

              runSearch({
                query,
                page: 1,
                onBeforeSearch: () => {
                  setQuery(query);
                },
                onComplete: () => {
                  setStatus({ submitted: true, errored: false, message: null });
                  setSubmitting(false);
                },
                onError: e => {
                  setStatus({ submitted: false, errored: true, message: e.message });
                  setSubmitting(false);
                },
              });
            }}
          />
        </div>

        {hasSearched && (
          <Archive
            hasErrored={hasErrored}
            isLoading={isLoading}
            items={results}
            hasSimpleHeader
            titleText={resultsTitle}
            filterItems={[]}
            rootUrl={''}
            filteredUrl={''}
            activeFilter={''}
            filterTaxonomy={''}
            selectFilterText={'Select result type'}
            openFilterText={'Open filter'}
            closeFilterText={'Close filter'}
            clearFilterText={''}
            rootFilterText={'All results'}
            pagination={{ ...pagination, hasButtons: true, onPageClick, onNextClick, onPreviousClick }}
            filterOpen={false}
            toggleFilterClick={() => {}}
            renderActiveFilter={filterName => {
              return `Posts about ${filterName}`;
            }}
            isList
            hasNoResults={hasNoResults}
            getCardProps={({ id, title, slug, excerpt }) => {
              return {
                id,
                title,
                slug,
                excerpt,
              };
            }}
            renderCard={item => {
              const { title, slug, excerpt, content } = item;

              const imageObj = null;
              const categoryName = '';
              const categorySlug = '';
              const date = '';

              return (
                <BlogPostCard
                  title={title}
                  slug={slug}
                  date={date}
                  excerpt={excerpt}
                  content={content}
                  image={imageObj}
                  categoryName={categoryName}
                  categorySlug={categorySlug}
                  isListing
                />
              );
            }}
            renderError={() => {
              return <p>There has been a problem performing your search. Please refresh the page and try again</p>;
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
        ogImage: opengraph_image {
          localFile {
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        twitterImage: twitter_image {
          localFile {
            childImageSharp {
              resize(width: 1000, height: 1000) {
                src
              }
            }
          }
        }
      }
      title
    }
  }
`;

export default Search;
