export function getOffset(pageNumber, postsPerPage) {
  // 6 per page, page 1 = 6 * 0 (1-1) = 0
  // 6 per page, page 2 = 6 * 1 (2-1) = 6
  // 6 per page, page 3 = 6 * 2 (3-1) = 12

  return postsPerPage * (pageNumber - 1);
}

export function getPageCount(total, postsPerPage) {
  return Math.ceil(total / postsPerPage);
}
