import classNames from 'classnames';
import React, { useState } from 'react';
import TextInputNew from './text-input-new';

const formField = ({
  field,
  wrapperClass,
  formName,
  id,
  label,
  required,
  className,
  placeholder,
  errored,
  error,
  hideErrorMessage,
  staticErrorMessage,
  children,
  innerRef,
  type,
}) => {
  const { value, name } = field;

  let initialHasValue = typeof value !== 'undefined' && value !== null;

  initialHasValue = initialHasValue && typeof value === 'string' && value.length > 0;

  const [hasValue, updateValue] = useState(initialHasValue);

  let rootClass = 'form-field';

  const cssClass = classNames(rootClass, {
    [`${rootClass}--has-value`]: hasValue,
    [`${rootClass}--invalid`]: errored,
    [`${rootClass}--hide-error-message`]: hideErrorMessage,
    [`${rootClass}--static-error-message`]: staticErrorMessage,
    [`${wrapperClass}`]: wrapperClass,
  });

  let fieldPlaceholder = placeholder || label;

  if (required) {
    fieldPlaceholder = `${fieldPlaceholder} *`;
  }

  const fieldId = `${formName}-${id}`;

  return (
    <div className={cssClass}>
      <TextInputNew
        id={fieldId}
        name={name}
        className={`form-field__input${className ? ` ${className}` : ''}`}
        required={required}
        placeholder={fieldPlaceholder}
        ref={innerRef}
        onInput={({ target }) => {
          const { value } = target;

          if (value.length > 0) {
            return updateValue(true);
          }

          return updateValue(false);
        }}
        type={type}
        {...field}
      />

      <label className="form-field__label" htmlFor={fieldId}>
        {label}
        {required && ' *'}
      </label>
      {errored && error(name, 'form-field__error')}
      {children}
    </div>
  );
};

export default formField;
