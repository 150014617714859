import React from 'react';

const TextInput = React.forwardRef(({ className, type, field, ...props }, ref) => {
  return <input className={`text-input${className ? ` ${className}` : ''}`} type={type} {...props} ref={ref} />;
});

TextInput.defaultProps = {
  type: 'text',
};

export default TextInput;
